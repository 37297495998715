export const ENDPOINTS = {
  subscription: {
    main: "subscription",
    otherSettings: "subscription/otherSettings/admin",
    // algorithmSettings: "subscription/AlgorithmSettings/admin",
    exists: "subscription/exists",
    users: "subscription/users",
    multiCustomerInvite: "subscription/multiCustomer/invite",
    resetPassword: "subscription/user/password",
    signupEnable: "subscription/signup/enable",
    bucket: "subscription/bucket",
  },
  auth: {
    login: "auth/admin/login",
    // me: "auth/Me",
    password: "auth/admin/password",
    logout: {
      admin: "auth/logout/admin",
    },
  },
  csrf: {
    admin: "csrf/admin",
  },
  user: {
    main: "user",
    exists: "user/exists",
  },
  scrape: {
    metrics: "scrape/metrics",
  },
};
