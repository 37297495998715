import { createContext, FC, useContext, useEffect } from "react";
import { AuthContextType } from "./types";
import { Credentials } from "types/credentials";
import client from "utils/clients/client";
import { ENDPOINTS } from "config/endpoints";
import { Request } from "types/request";
import { useAsync } from "hooks/useAsync";
import { ADMIN_TOKEN_KEY } from "config/localStorage";
import Cookies from "universal-cookie";

import PATH from "routing/path";
import { Response } from "types/response";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Constants } from "utils/constants/constants";
import { Admin } from "types/admin";

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);
AuthContext.displayName = "AdminContext";

const AuthProvider: FC = ({ children }) => {
  const {
    data: admin,
    error,
    isLoading,
    isError,
    setData,
    run,
    reset,
  } = useAsync<Admin>();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const cookies = new Cookies();
  const csrfTokenKey = process.env.REACT_APP_CRONO_CSRF_COOKIE as string;
  const refreshCsrfToken = async () => {
    try {
      const csrf = await getCsrf();
      //New way to set the csrf from the headers
      if (csrf && csrf.headers) {
        localStorage.setItem(csrfTokenKey, csrf.headers["xsrf-token-admin"]);
      }
    } catch (error) {}
  };

  const getCsrf = async () => {
    const request: Request = {
      url: ENDPOINTS.csrf.admin,
      config: {
        method: "GET",
      },
    };
    return await client(request);
  };

  async function setUserTokens() {
    await refreshCsrfToken();
  }

  const login = ({ email, password }: Credentials) => {
    const request: Request = {
      url: ENDPOINTS.auth.login,
      config: {
        method: "post",
        data: { email, password },
      },
    };

    run(
      client(request).then(async (result: Response<{ data: Admin }>) => {
        if (result.data) {
          const user = result.data.data;
          try {
            await setUserTokens();
          } catch (error) {}
          return user;
        }
      })
    );
  };

  const logout = () => {
    setData(null);
    localStorage.removeItem(ADMIN_TOKEN_KEY);
    cookies.remove(Constants.cronoAdminSessionCookie);
    queryClient.clear();
    const request: Request = {
      url: ENDPOINTS.auth.logout.admin,
      config: {
        method: "get",
      },
    };
    client(request);
    navigate(PATH.LOGIN);
  };

  const useAuthGuard = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!admin) {
        navigate(
          `${PATH.LOGIN}?returnUrl=${location.pathname}?${location.search}`
        );
      }
    }, [admin]);
  };

  const value = {
    admin,
    error,
    isLoading,
    isError,
    cookies,
    login,
    logout,
    useAuthGuard,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}
