import { Request } from "types/request";
import axios from "axios";
import Cookies from "universal-cookie";

export const cookies = new Cookies();
const client = (req: Request) => {
  const csrfToken = localStorage.getItem(
    process.env.REACT_APP_CRONO_CSRF_COOKIE as string
  );
  const headers = {
    ...(csrfToken && {
      "X-csrf-token-admin": csrfToken,
    }),
  };
  const { url, config } = req;
  return axios(`${process.env.REACT_APP_BE_URL}/${url}`, {
    ...config,
    headers: headers,
    withCredentials: true,
  });
};

export default client;
