import { FC, useMemo } from "react";
import { SubscriptionStatusWrapper } from "./styles";
import { FlexDiv } from "components/Layout/FlexDiv";
import { SubscriptionStatusProps } from "./types";
import moment from "moment";

const SubscriptionStatus: FC<SubscriptionStatusProps> = ({ subscription }) => {
  //Get expiration date and set the date of that as the current date in local time (no UTC)

  const currentExpDate = useMemo(() => {
    //From the BE we retrieve an UTC time, but we do not want that showing it with the local time
    //and the local timezone of the user it changes, so we set the date, month, year, hours and minutes
    //of the UTC time to the local time
    if (!subscription.expirationDate) {
      return null;
    }
    const oldD = new Date(subscription.expirationDate);
    const newD = new Date(subscription.expirationDate);
    newD.setDate(oldD.getUTCDate());
    newD.setMonth(oldD.getUTCMonth());
    newD.setFullYear(oldD.getUTCFullYear());
    newD.setHours(oldD.getUTCHours());
    newD.setMinutes(oldD.getUTCMinutes());
    return newD;
  }, [subscription.expirationDate]);

  return (
    <SubscriptionStatusWrapper>
      <div className="controls-container">
        <FlexDiv className={`control red`}>
          {subscription.company.integrationType}
        </FlexDiv>
        <FlexDiv className={`control blue`}>
          {/* format date */}
          {moment(currentExpDate).format("DD/MM/YYYY")}
        </FlexDiv>
        <FlexDiv
          className={`control light-blue`}
          style={{
            textAlign: "center",
          }}
        >
          {!subscription.multiCustomer
            ? "Licenses : " + subscription.enabledUsers.Basic
            : "Multicustomer"}
          <br />
          Active Users:{" "}
          {subscription.users?.filter((user) => user.active).length}
        </FlexDiv>
        <FlexDiv className={`control yellow`}>
          {subscription.lastLogin
            ? moment(subscription.lastLogin).format("DD/MM/YYYY")
            : "-"}
        </FlexDiv>
        <FlexDiv className={`control green`}>
          {subscription.subscriptionType}
        </FlexDiv>
        <FlexDiv
          className={`control light-blue`}
          style={{
            textAlign: "center",
          }}
        >
          {subscription.active ? "Active" : "Inactive"}
        </FlexDiv>
      </div>
    </SubscriptionStatusWrapper>
  );
};

export default SubscriptionStatus;
