import { useAuth } from "context/auth";
import { FC, useEffect } from "react";
import { LoginWrapper } from "./styles";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import { Input } from "components/FormComponents";
import PATH from "routing/path";
import { initialValues, Inputs, validationSchema } from "./model";
import Logo from "../../assets/images/logo_crono.png";
import Page from "components/Layout/Page";
import { useNavigate, useLocation } from "react-router-dom";
import { FlexDiv } from "components/Layout/FlexDiv";
import { CircularProgress } from "@mui/material";
import queryString from "query-string";
import { useConditionalSnackBar } from "context/snackbar";
import { useSelectedSubscription } from "pages/home/context/subscription";
import { useSearchBar } from "context/searchbar";

const Login: FC = () => {
  const { admin, error, login, isLoading } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { setSubscription: setAccount } = useSelectedSubscription();

  const { setSearchBarText } = useSearchBar();

  const formik = useFormik<Inputs>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login(values);
      setAccount(null);
      setSearchBarText("");
    },
  });

  useEffect(() => {
    const returnUrl = queryString.parse(search).returnUrl;
    if (admin && returnUrl) {
      navigate((returnUrl as string) || PATH.HOME);
      return;
    }
    if (admin) {
      navigate(PATH.HOME);
      return;
    }
  }, [admin]);

  useConditionalSnackBar([
    {
      condition: !!error && error.response?.status === 401,
      message: "Invalid credentials",
      severity: "error",
    },
    {
      condition: !!error && error.response?.status !== 401,
      message: "Something went wrong, please try again",
      severity: "error",
    },
  ]);

  if (isLoading) {
    return (
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    );
  }

  return (
    <Page>
      {!admin && (
        <LoginWrapper>
          <>
            <div className="login-card">
              <div className="login-text">
                <h2>Welcome to</h2>
                <img src={Logo} className="logo" alt="Crono Logo" />
                <h2>Admin Dashboard</h2>
                <p>Insert your credentials</p>
              </div>

              <form onSubmit={formik.handleSubmit} className="login-form">
                <Input
                  className="form-field"
                  fullWidth
                  id="email"
                  name="email"
                  label="E-mail"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <Input
                  className="form-field"
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  LOGIN
                </Button>
              </form>
            </div>
          </>
        </LoginWrapper>
      )}
    </Page>
  );
};

export default Login;
