import { CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import { CronoButton } from "components/CronoButton";
import { FlexDiv } from "components/Layout/FlexDiv";
import { ModalFormWrapper } from "components/Modal/styles";
import { useConditionalSnackBar } from "context/snackbar";
import { useFormik } from "formik";
import useSubscriptionExists from "hooks/services/subscription/useSubscriptionExists";
import useCreateSubscription from "hooks/services/subscription/useCreateSubscription";
import { ActiveSwitch } from "pages/home/SubscriptionInfo/styles";
import { FC, useEffect, useState } from "react";
import { trimObject } from "utils/object";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  initialValues,
  validationSchema,
  userInitialValues,
  userValidationSchema,
  SubscriptionInputs,
  InsertSubscriptionProps,
} from "./model";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { UserInsert } from "types/userInsert";
import { getError } from "utils";
import SubscriptionType from "types/enums/subscriptionType";
import { Constants } from "utils/constants/constants";
import { EditRounded } from "@mui/icons-material";
import EditDomainsModal from "pages/home/SubscriptionInfo/editDomainsModal";
import { createPortal } from "react-dom";

const AddSubscriptionForm: FC<InsertSubscriptionProps> = ({ setOpen }) => {
  const {
    isSuccess,
    isLoading,
    mutateAsync: createSubscription,
    error,
  } = useCreateSubscription();
  const [editingComplete, setEditingComplete] = useState(false);
  const [userError, setUserError] = useState<string | null>(null);

  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userRoles, setUserRoles] = useState<string[]>(["Basic"]);

  useEffect(() => {
    formik.values.expirationDate = new Date();
    // add 1 year to the current date
    formik.values.expirationDate.setFullYear(
      formik.values.expirationDate.getFullYear() + 1
    );
    formik.values.users = [];
  }, []);

  const formik = useFormik<SubscriptionInputs>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const trimmed = trimObject(values);
      if (formik.values.users.length === 0 && !formik.values.multiCustomer) {
        setUserError("Please add at least one user");
        return;
      }
      if (
        !formik.values.multiCustomer &&
        formik.values.users.filter((u) => u.userRoles.includes("Manager"))
          .length === 0
      ) {
        setUserError("Please add at least one manager");
        return;
      }
      if (checkSubscriptionExistsError()) return false;

      createSubscription({
        company: {
          name: trimmed.companyName,
          website: trimmed.companyWebsite,
          linkedinUrl: trimmed.companyLinkedinUrl,
        },
        customProperties: null,
        expirationDate: trimmed.expirationDate.toISOString(),
        integrationType: trimmed.integrationType,
        callIntegrationType:
          trimmed.callIntegrationType === "None"
            ? null
            : trimmed.callIntegrationType,
        externalProperties: null,
        subscriptionType: trimmed.subscriptionType ?? SubscriptionType.STARTER,
        enabledUsers: {
          Basic: trimmed.basicUserQuantity,
          Manager: trimmed.managerUserQuantity,
        },
        otherSettings: {
          currency: trimmed.currency,
          hasCall: trimmed.hasCall,
          hasLinkedin: trimmed.hasLinkedin,
          hasEmail: trimmed.hasEmail,
          hasLastFeatures: trimmed.hasLastFeatures,
          monthGenerateTemplateRequest: trimmed.monthGenerateTemplateRequest,
          monthScrapeRequest: trimmed.monthScrapeRequest,
          monthFindPhoneRequest: trimmed.monthFindPhoneRequest,
          monthVerifyRequest: trimmed.monthVerifyRequest,
          daysOnLastMeeting: 90,
          waitFirstIntegration: true,
          hasOpportunities: true,
          hideTeamAnalyticsIfBasicUser: false,
          subscriptionBasedCredits: true,
          logLinkedinReplies: true,
          logEmailReplies: true,
        },
        multiCustomer: trimmed.multiCustomer,
        subscriptionDomains: trimmed.subscriptionDomains,
        priceId: trimmed.priceId,
        coupon: trimmed.coupon,
        users: trimmed.users,
      }).then(() => {
        setOpen(false);
      });
    },
  });

  const userFormik = useFormik<UserInsert>({
    initialValues: userInitialValues,
    validationSchema: userValidationSchema,
    onSubmit: async (values) => {
      setUserError(null);
      const trimmed = trimObject(values);
      const user = {
        ...trimmed,
      };
      // check if user is presents
      if (formik.values.users.find((u) => u.email === user.email)) {
        setUserError("User already present");
        return false;
      }

      formik.values.users.push(user);
      userFormik.values = userInitialValues;
      setUserFirstName("");
      setUserLastName("");
      setUserEmail("");
      setUserRoles(["Basic"]);
    },
  });

  const { data: subscriptions, isLoading: subscriptionExistsloading } =
    useSubscriptionExists(formik.values.companyName, editingComplete);

  // const [topTier, setTopTier] = useState<boolean>(false);

  // const handleTopTierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setTopTier(e.target.checked);
  // };

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) || "Error creating subscription",
      severity: "error",
    },
    {
      condition: !!userError,
      message: userError || "",
      severity: "error",
    },
    {
      condition: !!isSuccess,
      message: "Subscription created succesfully",
      severity: "success",
    },
  ]);

  const checkSubscriptionExistsError = () => {
    if (subscriptionExistsloading) {
      return false;
    }
    if (subscriptions?.data?.data && subscriptions.data.data.length > 0) {
      return true;
    }
    return false;
  };

  const getSubscriptionExistsError = () => {
    let errorMessage = "";
    if (subscriptionExistsloading) {
      return errorMessage;
    }
    if (subscriptions?.data?.data && subscriptions.data.data.length > 0) {
      const subscription = subscriptions.data.data[0];
      errorMessage = `Subscription with name ${subscription.company.name} already exists`;
    }

    return errorMessage;
  };

  function updateExpirationDate(value: Date | null) {
    formik.setFieldValue("expirationDate", value);
  }

  function handleRemoveUser(email: string) {
    const users = formik.values.users.filter((u) => u.email !== email);
    formik.setFieldValue("users", users);
  }

  const handleMulticustomerChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    formik.setFieldValue("multiCustomer", e.target.checked);
    let expirationDate = new Date();
    if (e.target.checked) {
      expirationDate.setFullYear(expirationDate.getFullYear() + 5);
    } else {
      if (formik.values.subscriptionType === SubscriptionType.FREE_TRIAL) {
        expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 14);
      } else {
        expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      }
    }
    formik.setFieldValue("expirationDate", expirationDate);
  };
  function getPrices() {
    // if environment is dev
    if (process.env.NODE_ENV === "development") {
      return Constants.pricesDev;
    }
    return Constants.pricesPro;
  }

  const [showDomainsModal, setShowDomainsModal] = useState(false);

  const handleOpenDomainsModal = () => {
    setShowDomainsModal(true);
  };

  const updateSubscriptionDomains = (values: string[] | null) => {
    formik.setFieldValue("subscriptionDomains", values);
    setShowDomainsModal(false);
  };

  return (
    <ModalFormWrapper>
      {showDomainsModal &&
        createPortal(
          <EditDomainsModal
            close={() => setShowDomainsModal(false)}
            initialDomains={formik.values.subscriptionDomains}
            onSubmit={updateSubscriptionDomains}
          />,
          document.body
        )}
      <form onSubmit={formik.handleSubmit} className="prospect-form">
        <h4>Subscription Info</h4>
        <div className="prospect-info-row">
          <p className="add-new-label">Company Name</p>
          <TextField
            className="add-new-field"
            id="companyName"
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onFocus={() => setEditingComplete(false)}
            onBlur={() => setEditingComplete(true)}
            error={
              (formik.touched.companyName &&
                Boolean(formik.errors.companyName)) ||
              checkSubscriptionExistsError()
            }
            helperText={
              (formik.touched.companyName && formik.errors.companyName) ||
              getSubscriptionExistsError()
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Company Website</p>
          <TextField
            className="add-new-field"
            id="companyWebsite"
            name="companyWebsite"
            value={formik.values.companyWebsite}
            onChange={formik.handleChange}
            onFocus={() => setEditingComplete(false)}
            onBlur={() => setEditingComplete(true)}
            error={
              formik.touched.companyWebsite &&
              Boolean(formik.errors.companyWebsite)
            }
            helperText={
              formik.touched.companyWebsite && formik.errors.companyWebsite
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Company Linkedin Url</p>
          <TextField
            className="add-new-field"
            id="companyLinkedinUrl"
            name="companyLinkedinUrl"
            value={formik.values.companyLinkedinUrl}
            onChange={formik.handleChange}
            onFocus={() => setEditingComplete(false)}
            onBlur={() => setEditingComplete(true)}
            error={
              formik.touched.companyLinkedinUrl &&
              Boolean(formik.errors.companyLinkedinUrl)
            }
            helperText={
              formik.touched.companyLinkedinUrl &&
              formik.errors.companyLinkedinUrl
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Basic User Max</p>
          <TextField
            className="add-new-field"
            id="basicUserQuantity"
            name="basicUserQuantity"
            value={formik.values.basicUserQuantity}
            onChange={formik.handleChange}
            error={
              formik.touched.basicUserQuantity &&
              Boolean(formik.errors.basicUserQuantity)
            }
            helperText={
              formik.touched.basicUserQuantity &&
              formik.errors.basicUserQuantity
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Manager User Max</p>
          <TextField
            className="add-new-field"
            id="managerUserQuantity"
            name="managerUserQuantity"
            value={formik.values.managerUserQuantity}
            onChange={formik.handleChange}
            error={
              formik.touched.managerUserQuantity &&
              Boolean(formik.errors.managerUserQuantity)
            }
            helperText={
              formik.touched.managerUserQuantity &&
              formik.errors.managerUserQuantity
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Subscription type</p>
          <Select
            className="account-info-select"
            id="subscriptionType"
            name="subscriptionType"
            value={formik.values.subscriptionType}
            onChange={formik.handleChange}
            onBlur={() => {
              const expirationDate = new Date();
              if (
                formik.values.subscriptionType === SubscriptionType.FREE_TRIAL
              ) {
                expirationDate.setDate(expirationDate.getDate() + 14);
              } else {
                expirationDate.setFullYear(expirationDate.getFullYear() + 1);
              }
              formik.setFieldValue("expirationDate", expirationDate);
            }}
            error={
              formik.touched.subscriptionType &&
              Boolean(formik.errors.subscriptionType)
            }
          >
            <MenuItem key="freetrial" value={SubscriptionType.FREE_TRIAL}>
              Free Trial
            </MenuItem>
            <MenuItem key="starter" value={SubscriptionType.STARTER}>
              Starter
            </MenuItem>
            <MenuItem key="pro" value={SubscriptionType.PRO}>
              Pro
            </MenuItem>
            <MenuItem key="ultra" value={SubscriptionType.ULTRA}>
              Ultra
            </MenuItem>
          </Select>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Integration type</p>
          <Select
            className="account-info-select"
            id="integrationType"
            name="integrationType"
            value={formik.values.integrationType}
            onChange={formik.handleChange}
            error={
              formik.touched.integrationType &&
              Boolean(formik.errors.integrationType)
            }
          >
            <MenuItem key="hubspot" value={"hubspot"}>
              Hubspot
            </MenuItem>
            <MenuItem key="salesforce" value={"salesforce"}>
              Salesforce
            </MenuItem>
            <MenuItem key="pipedrive" value={"pipedrive"}>
              Pipedrive
            </MenuItem>
            <MenuItem key="crono" value={"crono"}>
              Crono
            </MenuItem>
          </Select>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Call integration type</p>
          <Select
            className="account-info-select"
            id="callIntegrationType"
            name="callIntegrationType"
            value={formik.values.callIntegrationType}
            onChange={formik.handleChange}
            error={
              formik.touched.callIntegrationType &&
              Boolean(formik.errors.callIntegrationType)
            }
          >
            <MenuItem key="Aircall" value={"Aircall"}>
              Aircall
            </MenuItem>
            <MenuItem key="None" value={"None"}>
              None
            </MenuItem>
          </Select>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Expiration Date</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formik.values.expirationDate}
              className="input-date"
              inputFormat="dd/MM/yyyy"
              onChange={(value) => updateExpirationDate(value)}
              renderInput={(params) => (
                <TextField className="input-date" fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
        </div>
        {Boolean(formik.errors.expirationDate) && (
          <p className="error-message">{formik.errors.expirationDate}</p>
        )}
        <div className="prospect-info-row">
          <p className="add-new-label">MultiCustomer</p>
          <div style={{ width: "300px" }}>
            <ActiveSwitch
              id="multiCustomer"
              name="multiCustomer"
              checked={formik.values.multiCustomer}
              onChange={handleMulticustomerChange}
            />
          </div>
        </div>
        {formik.values.multiCustomer && (
          <>
            <div className="prospect-info-row">
              <p className="add-new-label">Subscription Domain</p>
              <div
                className="add-new-field"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <TextField
                  style={{
                    flex: 1,
                  }}
                  id="subscriptionDomains"
                  name="subscriptionDomains"
                  value={formik.values.subscriptionDomains?.join(", ") ?? ""}
                  onChange={formik.handleChange}
                  onFocus={() => setEditingComplete(false)}
                  onBlur={() => setEditingComplete(true)}
                  error={
                    formik.touched.subscriptionDomains &&
                    Boolean(formik.errors.subscriptionDomains)
                  }
                  helperText={
                    formik.touched.subscriptionDomains &&
                    formik.errors.subscriptionDomains
                  }
                  disabled={true}
                />
                <EditRounded
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={handleOpenDomainsModal}
                />
              </div>
            </div>
            <div className="prospect-info-row">
              <p className="add-new-label">Coupon</p>
              <TextField
                className="add-new-field"
                id="coupon"
                name="coupon"
                value={formik.values.coupon}
                onChange={formik.handleChange}
                onFocus={() => setEditingComplete(false)}
                onBlur={() => setEditingComplete(true)}
              />
            </div>
            <div className="prospect-info-row">
              <p className="add-new-label">PriceID</p>
              <TextField
                className="add-new-field"
                id="priceId"
                name="priceId"
                value={formik.values.priceId}
                onChange={formik.handleChange}
                onFocus={() => setEditingComplete(false)}
                onBlur={() => setEditingComplete(true)}
              />
              {/* select from pick list */}
              <Select
                className="account-info-select"
                id="priceId"
                name="priceId"
                value={formik.values.priceId}
                onChange={formik.handleChange}
                error={formik.touched.priceId && Boolean(formik.errors.priceId)}
              >
                {getPrices().map((price) => (
                  <MenuItem key={price.priceId} value={price.priceId}>
                    {price.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </>
        )}
        <h4>Other Settings</h4>
        <div className="prospect-info-row">
          <p className="add-new-label">Currency</p>
          <Select
            className="account-info-select"
            id="currency"
            name="currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
          >
            <MenuItem key="$" value={"$"}>
              $
            </MenuItem>
            <MenuItem key="€" value={"€"}>
              €
            </MenuItem>
          </Select>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Linkedin Channel</p>
          <div style={{ width: "300px" }}>
            <ActiveSwitch
              id="hasLinkedin"
              name="hasLinkedin"
              checked={formik.values.hasLinkedin}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Email Channel</p>
          <div style={{ width: "300px" }}>
            <ActiveSwitch
              id="hasEmail"
              name="hasEmail"
              checked={formik.values.hasEmail}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Call Channel</p>
          <div style={{ width: "300px" }}>
            <ActiveSwitch
              id="hasCall"
              name="hasCall"
              checked={formik.values.hasCall}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Month Email Credits Quantity</p>
          <TextField
            className="add-new-field"
            id="monthScrapeRequest"
            name="monthScrapeRequest"
            value={formik.values.monthScrapeRequest}
            onChange={formik.handleChange}
            error={
              formik.touched.monthScrapeRequest &&
              Boolean(formik.errors.monthScrapeRequest)
            }
            helperText={
              formik.touched.monthScrapeRequest &&
              formik.errors.monthScrapeRequest
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Month Verify Credits Quantity</p>
          <TextField
            className="add-new-field"
            id="monthVerifyRequest"
            name="monthVerifyRequest"
            value={formik.values.monthVerifyRequest}
            onChange={formik.handleChange}
            error={
              formik.touched.monthVerifyRequest &&
              Boolean(formik.errors.monthVerifyRequest)
            }
            helperText={
              formik.touched.monthVerifyRequest &&
              formik.errors.monthVerifyRequest
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Month Phone Credits Quantity</p>
          <TextField
            className="add-new-field"
            id="monthFindPhoneRequest"
            name="monthFindPhoneRequest"
            value={formik.values.monthFindPhoneRequest}
            onChange={formik.handleChange}
            error={
              formik.touched.monthFindPhoneRequest &&
              Boolean(formik.errors.monthFindPhoneRequest)
            }
            helperText={
              formik.touched.monthFindPhoneRequest &&
              formik.errors.monthFindPhoneRequest
            }
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Last Features</p>
          <div style={{ width: "300px" }}>
            <ActiveSwitch
              id="hasLastFeatures"
              name="hasLastFeatures"
              checked={formik.values.hasLastFeatures}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Month AI Credits Quantity</p>
          <TextField
            className="add-new-field"
            id="monthGenerateTemplateRequest"
            name="monthGenerateTemplateRequest"
            value={formik.values.monthGenerateTemplateRequest}
            onChange={formik.handleChange}
            error={
              formik.touched.monthGenerateTemplateRequest &&
              Boolean(formik.errors.monthGenerateTemplateRequest)
            }
            helperText={
              formik.touched.monthGenerateTemplateRequest &&
              formik.errors.monthGenerateTemplateRequest
            }
          />
        </div>
        {!formik.values.multiCustomer && (
          <>
            <h4>Users</h4>
            <div className="prospect-info-row">
              <p className="add-new-label">First Name</p>
              <TextField
                className="add-new-field"
                id="firstName"
                name="firstName"
                value={userFirstName}
                onChange={(e) => {
                  setUserFirstName(e.target.value);
                  userFormik.values.firstName = e.target.value;
                }}
                onFocus={() => setEditingComplete(false)}
                onBlur={() => setEditingComplete(true)}
                error={
                  userFormik.touched.firstName &&
                  Boolean(userFormik.errors.firstName)
                }
                helperText={
                  userFormik.touched.firstName && userFormik.errors.firstName
                }
              />
            </div>
            <div className="prospect-info-row">
              <p className="add-new-label">Last Name</p>
              <TextField
                className="add-new-field"
                id="lastName"
                name="lastName"
                value={userLastName}
                onChange={(e) => {
                  setUserLastName(e.target.value);
                  userFormik.values.lastName = e.target.value;
                }}
                onFocus={() => setEditingComplete(false)}
                onBlur={() => setEditingComplete(true)}
                error={
                  userFormik.touched.lastName &&
                  Boolean(userFormik.errors.lastName)
                }
                helperText={
                  userFormik.touched.lastName && userFormik.errors.lastName
                }
              />
            </div>
            <div className="prospect-info-row">
              <p className="add-new-label">Email</p>
              <TextField
                className="add-new-field"
                id="email"
                name="email"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                  userFormik.values.email = e.target.value;
                }}
                onFocus={() => setEditingComplete(false)}
                onBlur={() => setEditingComplete(true)}
                error={
                  userFormik.touched.email && Boolean(userFormik.errors.email)
                }
                helperText={userFormik.touched.email && userFormik.errors.email}
              />
            </div>
            <div className="prospect-info-row">
              <p className="add-new-label">Roles</p>
              <Select
                className="account-info-select"
                id="userRoles"
                name="userRoles"
                value={userRoles}
                onChange={(e) => {
                  const { value } = e.target;
                  // if value is array
                  console.log(value);
                  const roles = [];
                  if (Array.isArray(value)) {
                    setUserRoles(value);
                    roles.push(...value);
                  } else {
                    setUserRoles([value]);
                    roles.push(value);
                  }
                  userFormik.values.userRoles = roles;
                }}
                multiple
              >
                <MenuItem key="Basic" value={"Basic"}>
                  Basic
                </MenuItem>
                <MenuItem key="Manager" value={"Manager"}>
                  Manager
                </MenuItem>
              </Select>
            </div>
            {Boolean(userFormik.errors.userRoles) && (
              <p className="error-message">{userFormik.errors.userRoles}</p>
            )}
            <CronoButton
              variant="contained"
              onClick={() => {
                userFormik.handleSubmit();
              }}
            >
              Add User
            </CronoButton>
            <FlexDiv
              className="prospect-info-row"
              justifyContent="space-between"
              alignItems="start"
              direction="column"
            >
              <p className="add-new-label">
                Added Users: {formik.values.users.length}
              </p>
              <div className="added-users">
                {formik.values.users.map((user, index) => (
                  <div className="added-user" key={index}>
                    <FlexDiv direction="row">
                      <p>
                        {user.email} - {user.userRoles.join(", ")}{" "}
                      </p>
                      <DeleteIcon
                        onClick={() => handleRemoveUser(user.email)}
                        color="error"
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    </FlexDiv>
                  </div>
                ))}
              </div>
            </FlexDiv>
          </>
        )}
        <div className="button-container">
          {isLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <CronoButton
              variant="contained"
              type="submit"
              disabled={checkSubscriptionExistsError()}
            >
              Insert
            </CronoButton>
          )}
        </div>
      </form>
    </ModalFormWrapper>
  );
};

export default AddSubscriptionForm;
